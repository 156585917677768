<template>
  <div class="small">
    <clients-chart 
      v-if="loaded" 
      :chart-data="datacollection"
    ></clients-chart>
  </div>
</template>

<script>
  import ClientsChart from './clientsLine.js'

  export default {
    components: {
      ClientsChart
    },
    data () {
      return {
        datacollection: null,
        loaded: false,
        time: [0,1,2,3,4,5,6,7,8,9]
      }
    },
    async mounted () {
      this.fillData()
      this.loaded = true
    },
    methods: {
      fillData () {
        this.datacollection = {
          labels: this.time,
          datasets: [
            {
              label: 'Temp (°C)',
              backgroundColor: 'rgba(0, 0, 255, 0.2)',
              borderColor: 'lightblue', 
              pointBackgroundColor: 'blue', 
              borderWidth: 1, 
              pointBorderColor: 'blue',
              data: [1,2,3,2,1,3,4,6,7,8]
            }, 
            {
              label: 'Hum (%)',
              backgroundColor: 'rgba(255, 0, 0, 0.2)',
              borderColor: 'lightpink', 
              pointBackgroundColor: 'red', 
              borderWidth: 1, 
              pointBorderColor: 'red',
              data: [6,7,6,8,7,8,6,7,6,6]
            },
            {
              label: 'Potentio',
              backgroundColor: 'rgba(255, 255, 0, 0.2)',
              borderColor: 'yellow', 
              pointBackgroundColor: 'orange', 
              borderWidth: 1, 
              pointBorderColor: 'orange',
              data: [3,2,4,5,2,5,6,3,3,6]
            }
          ]
        }
      },
    }
  }
</script>

<style>
  .small {
    width: 500px;
    height: 100px;
    margin: auto
  }
</style>